import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ArticlePageComponent, ContactPageComponent, PrivacyPolicyPageComponent, StartPageComponent} from './pages';
import {AutoLoginPartialRoutesGuard} from 'angular-auth-oidc-client';
import {ArticleOverviewComponent} from "./pages/article-overview";
import {RssPageComponent} from "./pages/rss-page";
import {AboutPageComponent} from "./pages/about-page";


const routes: Routes = [
  {path: '', component: StartPageComponent},
  {path: 'contact', component: ContactPageComponent},
  {path: 'about', component: AboutPageComponent},
  {path: 'privacy-policy', component: PrivacyPolicyPageComponent},
  {
    path: 'articles', children: [
      {path: 'detail', component: ArticlePageComponent},
      {path: 'overview', component: ArticleOverviewComponent}
    ]
  },
  // {path: 'rss.xml', component: RssPageComponent},

  {path: '', loadChildren: () => import('@b2beyond/shared').then(m => m.SharedLibraryRoutingModule)},
  {
    path: '', loadChildren: () => import('@b2beyond/payment').then(m => m.PaymentModule),
    canLoad: [AutoLoginPartialRoutesGuard]
  }
];

@NgModule({
  imports: [
    RouterModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
