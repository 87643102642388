import {createSelector} from '@ngrx/store';
import {entryState, selectEntryState} from './state.selector';
import {EntryState} from '../entry.reducer';


export const selectMiningScamsEntryLinks = createSelector(
  entryState,
  (state: EntryState) => state.entryArticlesLinks
);

export const selectWebsiteEntryLinks = createSelector(
  entryState,
  (state: EntryState) => state.websiteEntryLinks
);

export const selectTopics = createSelector(
  entryState,
  (state: EntryState) => state ? state.topics : undefined
);

export const selectTags = createSelector(
  entryState,
  (state: EntryState) => state ? state.tags : undefined
);

export const selectArticles = createSelector(
  entryState,
  (state: EntryState) => state ? state.articles : undefined
);

export const selectRecentArticles = createSelector(
  entryState,
  (state: EntryState) => state ? state.recentArticles : []
);

export const selectPagesCount = createSelector(
  selectEntryState,
  (state: EntryState) => {
    if (state) {
      console.log('Getting entry links !!! ', state);
      return state.pagesCount;
    } else {
      return 0;
    }
  }
);
