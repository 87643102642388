import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MiningScamsEntryHttpService} from './services/b2beyond.entry.service';
import {SecurityEntryHttpService, SessionUtil} from '@b2beyond/security';
import {TranslateModule} from '@ngx-translate/core';
import {MatDialogModule} from '@angular/material/dialog';
import {ContextMenuComponent} from '@b2beyond/shared';
import {PaymentEntryHttpService} from '@b2beyond/payment';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MatDialogModule,
  ],
  providers: [
    MiningScamsEntryHttpService,
    PaymentEntryHttpService,
    SecurityEntryHttpService,
    ContextMenuComponent,
    SessionUtil,
  ],
  exports: [
    TranslateModule
  ]
})
export class B2beyondSharedModule {
}
