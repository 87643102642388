<!-- Header -->
<header class="header shop">
  <div class="middle-inner">
    <div class="container">
      <div class="row">
        <div class="col-lg-2 col-md-2 col-12">
          <!-- Logo -->
          <div class="logo">
            <a href="index.html"><img ngSrc="logo/logo-flat.svg" fill alt="Mining scams logo"></a>
          </div>
          <!--/ End Logo -->
          <div class="mobile-nav"></div>
        </div>
        <div class="col-lg-8 col-md-8 col-12" *ngIf="topics$ | async">
          <div class="search-bar-top">
            <div class="search-bar">
              <select #topics>
                <option selected="selected">All Topics</option>
                <option *ngFor="let topic of topics$ | async" value="{{topic.id}}">{{topic.title}}</option>
              </select>
<!--              <form>-->
              <input #search placeholder="Search Articles Here....." type="search">
              <button class="btnn" aria-label="Search articles"><i class="ti-search" (click)="searchArticles()"></i></button>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-12">
          <a href="https://nowpayments.io/donation?api_key=GMG9TQB-Z6J4X4B-MPP6GSX-QX27DDC" target="_blank" rel="noreferrer noopener">
            <img src="https://nowpayments.io/images/embeds/donation-button-white.svg" alt="Cryptocurrency & Bitcoin donation button by NOWPayments">
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Header Inner -->
  <div class="header-inner">
    <div class="container">
      <div class="cat-nav-head">
        <div class="row">
<!--          <div class="col-lg-3">-->
<!--            <div class="all-category">-->
<!--              <h3 class="cat-heading"><i class="fa fa-bars" aria-hidden="true"></i>TOPICS</h3>-->
<!--              <ul class="main-category">-->
<!--                <li *ngFor="let topic of topics$ | async">-->
<!--                  <i class="icon-before-text fa fa-info-circle"><span class="tooltiptext">{{topic.description}}</span></i>-->
<!--                  <a href="#">{{topic.title}}<i class="fa fa-angle-right" aria-hidden="true"></i></a>-->
<!--                  <ul class="sub-category">-->
<!--                    <li *ngFor="let article of topic.articles"><a (click)="navigateToDetail(article.id)">{{article.title}}</a></li>-->
<!--                  </ul>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
          <div class="col-lg-12 col-12">
            <div class="menu-area">
              <!-- Main Menu -->
              <nav class="navbar navbar-expand-lg">
                <div class="navbar-collapse">
                  <div class="nav-inner">
                    <ul class="nav main-menu menu navbar-nav">
                      <li [ngClass]="{active: isRouteActive('/')}"><a [routerLink]="'/'">Home</a></li>
                      <li [ngClass]="{active: isRouteActive('/articles/overview')}"><a [routerLink]="'/articles/overview'">Articles</a></li>
                      <li [ngClass]="{active: isRouteActive('/contact')}"><a [routerLink]="'/contact'">Contact Us</a></li>
                    </ul>
                  </div>
                </div>
              </nav>
              <!--/ End Main Menu -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--/ End Header Inner -->
</header>
<!--/ End Header -->

@defer {
  <router-outlet></router-outlet>
} @loading (after 100ms; minimum 1s) {
  <img alt="loading..." src="loading.gif" />
}

<!-- Start Footer Area -->
<footer class="footer">
  <!-- Footer Top -->
  <div class="footer-top section">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-6 col-12">
          <!-- Single Widget -->
          <div class="single-footer about">
            <div class="logo">
              <img ngSrc="logo/logo-no-background.svg" fill style="position: relative" alt="Mining scams logo">
              <a [routerLink]="'/'"></a>
            </div>
            <p class="text">We are here to assure that you do not make mistakes when involving in the crypto world. Many scammers are luring to get your well earned money. So be aware and consult our articles.</p>
<!--            <p class="call">Got Question? Call us 24/7<span><a href="tel:123456789">+0123 456 789</a></span></p>-->
          </div>
          <!-- End Single Widget -->
        </div>
        <div class="col-lg-2 col-md-6 col-12">
          <!-- Single Widget -->
          <div class="single-footer links">
            <h1>Information</h1>
            <ul>
              <li><a [routerLink]="'/about'">About Us</a></li>
<!--              <li><a href="#">Faq</a></li>-->
<!--              <li><a href="#">Terms & Conditions</a></li>-->
              <li><a [routerLink]="'/contact'">Contact Us</a></li>
              <li><a [routerLink]="'/privacy-policy'">Privacy Policy</a></li>
<!--              <li><a href="#">Help</a></li>-->
              <li><a [routerLink]="'/rss.xml'">Rss feed</a></li>
            </ul>
          </div>
          <!-- End Single Widget -->
        </div>
      </div>
    </div>
  </div>
  <!-- End Footer Top -->
  <div class="copyright">
    <div class="container">
      <div class="inner">
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="left">
              <p>Copyright © 2024 <a href="https://miningscams.com" target="_blank" [routerLink]="'/'">Mining Scams</a>  -  All Rights Reserved</p>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="right">
              <p>Powered by <a href="https://b2beyond.org">B2beyond.org</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- /End Footer Area -->
<!--</app-wrapper>-->
