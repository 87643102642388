import {Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {MiningScamsEntryHttpService} from '../../shared/services/b2beyond.entry.service';
import {Observable} from 'rxjs';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from "@angular/router";
import {EntryState, selectMiningScamsEntryLinks, selectRecentArticles, selectTags, selectTopics} from "../../shared/store";
import {map, take} from "rxjs/operators";
import {Article, ConfigService} from "@b2beyond/shared";
import {AbstractSecurityComponent} from "@b2beyond/security";


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent extends AbstractSecurityComponent implements OnDestroy {

  @ViewChild('email') email: ElementRef | undefined;

  public recentArticles$: Observable<Article[]> | undefined = this.store.select(selectRecentArticles);
  public topics$ = this.store.select(selectTopics);
  public tags$ = this.store.select(selectTags);

  public toastVisible: boolean = false;
  public toastMessage: string | undefined;

  @Output()
  public onDetailClick = new EventEmitter<number>();
  @Output()
  public onTagClick = new EventEmitter<number>();
  @Output()
  public onTopicClick = new EventEmitter<number>();


  constructor(private readonly titleService: Title,
              private readonly route: ActivatedRoute,
              private readonly router: Router,
              private readonly entryService: MiningScamsEntryHttpService,
              private readonly configService: ConfigService,
              private readonly store: Store<EntryState>) {
    super();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigateToDetail(id: number) {
    this.onDetailClick.emit(id);
    this.router.navigateByUrl('/articles/detail?id=' + id);
  }

  gotoOverviewForTag(id: number) {
    this.onTagClick.emit(id);
    this.router.navigateByUrl('/articles/overview?tagId=' + id);
  }

  gotoOverviewForTopic(id: number) {
    this.onTopicClick.emit(id);
    this.router.navigateByUrl('/articles/overview?topicId=' + id);
  }

  subscribeToNewsletter() {
    this.store.select(selectMiningScamsEntryLinks).pipe(
      take(1),
      map(links => {
        if (this.email?.nativeElement) {
          this.toastVisible = true;
          this.entryService.subscribe(this.configService.getModuleConfig().websiteId, this.email.nativeElement.value, links).subscribe(() => {
            setTimeout(() => this.toastVisible = false, 3000);
            this.toastMessage = 'You have successfully subscribed to our newsletter!';
          });
          this.email.nativeElement.value = '';
        }
      })
    ).subscribe();
  }
}
