import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';


@Component({
  selector: 'app-about-page',
  templateUrl: './privacy-policy-page.component.html',
  styleUrls: ['./privacy-policy-page.component.scss'],
})
export class PrivacyPolicyPageComponent {

  constructor(private readonly titleService: Title) {
    titleService.setTitle('Mining scams - Privacy Policy');
  }

}
