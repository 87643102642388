<div class="container-fluid" *ngIf="!(loggedIn$ | async)">

  <!-- Slider Area -->
  <section class="hero-slider">
    <!-- Single Slider -->
    <div class="single-slider">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-lg-12 col-12">
            <div class="text-inner">
              <div class="row">
                <div class="col-lg-3 col-md-4 margin-top-75">
                  <gecko-coin-price-chart-widget locale="en" outlined="true" coin-id="bitcoin" initial-currency="usd" width="125" height="40"></gecko-coin-price-chart-widget>
                </div>
                <div class="col-lg-3 col-md-4 margin-top-75">
                  <gecko-coin-price-chart-widget locale="en" outlined="true" coin-id="kaspa" initial-currency="usd" width="125" height="40"></gecko-coin-price-chart-widget>
                </div>
                <div class="col-lg-3 col-md-4 margin-top-75">
                  <gecko-coin-price-chart-widget locale="en" outlined="true" coin-id="sedra-coin" initial-currency="usd" width="125" height="40"></gecko-coin-price-chart-widget>
                </div>
                <div class="col-lg-3 col-md-4 margin-top-75">
                  <gecko-coin-price-chart-widget locale="en" outlined="true" coin-id="alephium" initial-currency="usd" width="125" height="40"></gecko-coin-price-chart-widget>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        <!--/ End Single Slider -->
  </section>
  <!--/ End Slider Area -->

  <!-- Start Product Area -->
  <div class="product-area shop-blog section section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-title">
            <h2>Categories</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="product-info" *ngIf="topics$ | async as topics">
            <div class="nav-main margin-bottom-25">
              <!-- Tab Nav -->
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li *ngFor="let topic of topics" class="nav-item" role="tab">
                  <a class="nav-link" [ngClass]="{active: selectedTopic == topic.id}" data-toggle="tab" href="#topic-{{topic.id}}">{{topic.title}}</a>
                </li>
              </ul>
              <!--/ End Tab Nav -->
            </div>
            <div class="tab-content" id="myTabContent">
              <!-- Start Single Tab -->
              <div *ngFor="let topic of topics" class="nav-item" class="tab-pane fade" [ngClass]="{show: selectedTopic == topic.id, active: selectedTopic == topic.id}" id="topic-{{topic.id}}" role="tabpanel">
                <div class="masonry-with-columns">
                  <section>
                    <div *ngFor="let article of topic.articles" class="margin-bottom-25">
                      <div class="card">
                        <div class="card-header" *ngIf="article.cardImageLocation">
                          <img ngSrc="{{article.cardImageLocation}}" width="250" height="250" alt="#">
                        </div>
                        <div class="card-body">
                          <div class="tags">
                            <span *ngFor="let tag of article.tags" class="tag tag-purple" (click)="gotoOverviewForTag(tag)">{{tag}}</span>
                          </div>
                          <h3>{{article.title}}</h3>
                          <p class="small">{{article.smallDescription}}</p>
                          <div class="user">
                            <div class="user-info">
                              <div class="small-title">
                                <small>Written by {{article.author}}</small>
                                <br/>
                                <small>On {{article.creationDate}}</small>
                              </div>
                              <a (click)="navigateToDetail(article.id)" class="float-right read-more-btn pointer" [routerLink]="" queryParamsHandling="preserve">Read more ...</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

              </div>
              <!--/ End Single Tab -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Product Area -->

  <!-- Start Shop Services Area -->
  <section class="shop-services section home">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12">
          <!-- Start Single Service -->
          <div class="single-service">
            <div>
              <img ngSrc="/images/scam-alert.webp" width="100" height="100" class="info-image" alt="Scam alerts">
              <h4>Scam Alerts</h4>
              <p>Stay updated with the latest crypto scams and protect your investments.</p>
            </div>
          </div>
          <!-- End Single Service -->
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <!-- Start Single Service -->
          <div class="single-service">
            <div>
              <img ngSrc="/images/educational.webp" width="100" height="100" class="info-image" alt="Educational articles">
              <h4>Educational Articles</h4>
              <p>Read in-depth articles about the most common crypto frauds and how to avoid them.</p>
            </div>
          </div>
          <!-- End Single Service -->
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <!-- Start Single Service -->
          <div class="single-service">
            <div>
              <img ngSrc="/images/community.webp" width="100" height="100" class="info-image" alt="Community support">
              <h4>Community Support</h4>
              <p>Join a community of like-minded individuals who are committed to staying safe in the crypto space.</p>
            </div>
          </div>
          <!-- End Single Service -->
        </div>
      </div>
    </div>
  </section>
  <!-- End Shop Services Area -->

  <!-- Start Shop Newsletter  -->
  <section class="shop-newsletter section">
    <div class="container">
      <div class="inner-top">
        <div class="row">
          <div class="col-lg-8 offset-lg-2 col-12">
            <!-- Start Newsletter Inner -->
            <div class="inner">
              <h4>Newsletter</h4>
              <p> Subscribe to our newsletter and get the latest news</p>
<!--              <form method="get" target="_blank" class="newsletter-inner">-->
              <div class="form-inner">
                <div class="form-group">
                  <input #email type="email" placeholder="Your email address" class="form-control" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" id="email" name="email" ngModel #emailref="ngModel">
                  <button class="btn" (click)="subscribeToNewsletter()">Subscribe</button>

                  <div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty)" class ="alert alert-danger margin-top-25">
                    <div [hidden]="!emailref.errors?.pattern">Invalid email pattern</div>
                  </div>
                </div>
              </div>
<!--              </form>-->
            </div>
            <!-- End Newsletter Inner -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Shop Newsletter -->
</div>

<div id="myAlert" class="alert alert-info alert-dismissible fade" [ngClass]="{show: toastVisible}" role="alert">
  {{toastMessage}}
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
