<section id="contact-us" class="contact-us section">
  <div class="container">
    <div class="contact-head">
      <div class="row">
        <div class="col-lg-8 col-12">
          <div class="form-main">
            <div class="title">
              <h4>Get in touch</h4>
              <h3>Write us a message</h3>
            </div>
            <form class="form"
            >
              <div class="row">
                <div class="col-lg-6 col-12">
                  <div class="form-group">
                    <label>Your Name<span>*</span></label>
                    <input #name name="name" type="text" placeholder="Your name" ngModel #nameref="ngModel" required>
                    <div *ngIf="nameref.errors &&(nameref.touched || nameref.dirty)" class ="alert alert-danger margin-top-25">
                      <div [hidden]="!nameref.errors?.required">Required</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-12">
                  <div class="form-group">
                    <label>Your Email<span>*</span></label>
                    <input #email type="email" placeholder="Your email address" class="form-control" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" id="email" name="email" ngModel #emailref="ngModel" required>
                    <div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty)" class ="alert alert-danger margin-top-25">
                      <div [hidden]="!emailref.errors?.pattern">Invalid email pattern</div>
                      <div [hidden]="!emailref.errors?.required">Required</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-12">
                  <div class="form-group">
                    <label>Your Subject<span>*</span></label>
                    <input #subject name="subject" type="text" placeholder="Subject of your message" ngModel #subjectref="ngModel" required>
                    <div *ngIf="subjectref.errors &&(subjectref.touched || subjectref.dirty)" class ="alert alert-danger margin-top-25">
                      <div [hidden]="!subjectref.errors?.required">Required</div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group message">
                    <label>your message<span>*</span></label>
                    <textarea #message name="message" placeholder="Enter your message" ngModel #messageref="ngModel" required maxlength="1024"></textarea>
                    <span class="float-right">{{1024 - messageref.value?.length}} left</span>
                    <div *ngIf="messageref.errors &&(messageref.touched || messageref.dirty)"class ="alert alert-danger margin-top-25">
                      <div [hidden]="!messageref.errors?.required">Required</div>
                      <div [hidden]="!messageref.errors?.maxlength">Max length is 1024</div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group button">
                    <button type="submit" class="btn" (click)="sendMessage()" [disabled]="!nameref.valid || !emailref.valid || ! subjectref.valid || !messageref.valid">Send Message</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-4 col-12">
          <div class="single-head">
<!--            <div class="single-info">-->
<!--              <i class="fa fa-phone"></i>-->
<!--              <h4 class="title">Call us Now:</h4>-->
<!--              <ul>-->
<!--                <li>+123 456-789-1120</li>-->
<!--                <li>+522 672-452-1120</li>-->
<!--              </ul>-->
<!--            </div>-->
            <div class="single-info">
              <i class="fa fa-envelope-open"></i>
              <h4 class="title">Email:</h4>
              <ul>
                <li><a href="mailto:info@miningscams.com">info&#64;miningscams.com</a></li>
              </ul>
            </div>
            <div class="single-info">
              <i class="fa fa-telegram"></i>
              <h4 class="title">Our Telegram group:</h4>
              <ul>
                <li><a href="https://t.me/miningscamsdc">https://t.me/miningscamsdc</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
