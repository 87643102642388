import {Article, Link, PageData, ReceivedBlogEntryAction, Tag, Topic} from '@b2beyond/shared';
import {
  Actions,
  ReceivedArticlesAction,
  ReceivedPagesCountAction, ReceivedRecentArticlesAction, ReceivedTagsAction,
  ReceivedTopicsAction, ReceivedWebsiteEntryAction,
} from './actions/entry.actions';

export interface EntryState {
  websiteEntryLinks: Array<Link>;
  topics: Array<Topic> | undefined;
  tags: Array<Tag> | undefined;
  entryArticlesLinks: Array<Link> | undefined;
  articles: PageData<Article> | undefined;
  recentArticles: Article[];
  pagesCount: number
}

export let initialAppState: EntryState = {
  websiteEntryLinks: [],
  topics: undefined,
  tags: undefined,
  entryArticlesLinks: undefined,
  articles: undefined,
  recentArticles: [],
  pagesCount: 0
};

export function entryReducer(state: EntryState = initialAppState, action: Actions): EntryState {
  console.log('Setting entry state', state, action);

  switch (action.type) {
    case ReceivedWebsiteEntryAction.type:
      return {...state, websiteEntryLinks: action.payload.links}
    case ReceivedTopicsAction.type:
      return {...state, topics: action.payload}
    case ReceivedTagsAction.type:
      return {...state, tags: action.payload}
    case ReceivedBlogEntryAction.type:
      return {...state, entryArticlesLinks: action.payload.links};
    case ReceivedArticlesAction.type:
      return {...state, articles: action.payload};
    case ReceivedRecentArticlesAction.type:
      return {...state, recentArticles: action.payload};
    case ReceivedPagesCountAction.type:
      return {...state, pagesCount: action.payload};
    default:
      return state;
  }

}
