import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from "../../payment-gateway/src/environments/environment";
import {enableProdMode} from "@angular/core";

function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}

if (environment.production) {
  enableProdMode();
  console.log = function() {};
  console.info = function() {};
  console.error = function() {};
  console.debug = function() {};
  console.warn = function() {};
  console.trace = function() {};
}

if (document.readyState !== 'loading') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
