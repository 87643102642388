<div class="loader-container" *ngIf="!(article$ | async)">
  <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
</div>

<!-- Start Blog Single -->
<section class="blog-single section" *ngIf="article$ | async as article">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-12">
        <div class="blog-single-main">
          <div class="row">
            <div class="col-12">
              <div class="image" *ngIf="article.cardImageLocation !== ''">
                <img src="{{article.cardImageLocation}}" alt="#">
              </div>
              <div class="blog-detail">
                <h2 class="blog-title">{{article.title}}</h2>
                <div class="blog-meta">
                  <span class="author"><a class="cursor-none"><i class="fa fa-user"></i>By {{article.author}}</a><a class="cursor-none"><i class="fa fa-calendar"></i>{{article.creationDate}}</a><a class="cursor-none"><i class="fa fa-comments"></i>Comment ({{article.comments.length}})</a></span>
                </div>
                <div class="content">
                  <p [innerHTML]="article.description"></p>
                </div>
              </div>
              <div class="share-social">
                <div class="row">
                  <div class="col-12">
                    <div class="content-tags">
                      <h4>Tags:</h4>
                      <ul class="tag-inner">
                        <li *ngFor="let tag of article.tags"><a href="#">{{tag.name}}</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="comments">
                <h3 class="comment-title">Comments ({{article.comments.length}})</h3>
                <!-- Single Comment -->
                <div class="single-comment" *ngFor="let comment of article.comments">
                  <img src="https://via.placeholder.com/80x80" alt="#">
                  <div class="content">
                    <h4>{{comment.author}} <span>At {{comment.creationDate}}</span></h4>
                    <p>{{comment.description}}</p>
                  </div>
                </div>
                <!-- End Single Comment -->
              </div>
            </div>
            <div class="col-12">
              <div class="reply">
                <div class="reply-head">
                  <h2 class="reply-title">Leave a Comment</h2>
                  <!-- Comment Form -->
<!--                  <form class="form" action="#">-->
                  <form class="needs-validation text-center border border-light p-5 full-width"
                        [formGroup]="commentForm" novalidate>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="form-group">
                          <label>Your Name<span>*</span></label>
                          <input type="text" name="name" placeholder="" required="required" formControlName="author">
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="form-group">
                          <label>Your Email<span>*</span></label>
                          <input type="email" name="email" placeholder="" required="required" formControlName="email">
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label>Your Message<span>*</span></label>
                          <textarea name="message" placeholder="" formControlName="description"></textarea>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group button">
                          <button type="submit" class="btn" (click)="saveComment()">Post comment</button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <!-- End Comment Form -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-12">
        <app-sidebar (onDetailClick)="onDetailClick($event)"></app-sidebar>
      </div>
    </div>
  </div>
</section>
<!--/ End Blog Single -->
