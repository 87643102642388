import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {SecurityState, selectLoggedIn, selectUserAccounts, UserAccountDto} from '@b2beyond/security';
import {map, take, takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {EntryState, selectArticles, selectMiningScamsEntryLinks, selectTags, selectTopics} from '../../shared/store';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {ConfigService, LoadBlogEntryAction} from "@b2beyond/shared";
import {MiningScamsEntryHttpService} from "../../shared/services/b2beyond.entry.service";


@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss'],
})
export class StartPageComponent implements OnInit, AfterViewInit, OnDestroy {

  private destroy$ = new Subject<void>();

  @ViewChild('email') email: ElementRef | undefined;

  public articles$ = this.entryStore.select(selectArticles);
  public topics$ = this.entryStore.select(selectTopics);
  public tags$ = this.entryStore.select(selectTags);
  public selectedTopic: number | undefined;

  public toastVisible: boolean = false;
  public toastMessage: string | undefined;

  loggedIn$ = this.securityStore.pipe(select(selectLoggedIn));
  accounts$: Observable<Array<UserAccountDto>| undefined> = this.securityStore.select(selectUserAccounts).pipe();

  constructor(private readonly securityStore: Store<SecurityState>,
              private readonly entryStore: Store<EntryState>,
              private readonly oidcSecurityService: OidcSecurityService,
              private readonly miningScamsEntryHttpService: MiningScamsEntryHttpService,
              private readonly configService: ConfigService,
              private readonly router: Router) {
  }

  ngOnInit(): void {
    this.entryStore.dispatch(LoadBlogEntryAction());

    this.topics$.pipe(
      takeUntil(this.destroy$),
      map(topics => {
          if (topics && topics.length > 0) {
              this.selectedTopic = topics[0].id;
          }
      })
    ).subscribe();
  }

  ngAfterViewInit(): void {
    // @ts-ignore
    // import('./crypto-widget.js');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  login() {
    this.oidcSecurityService.authorize();
  }

  navigateToDetail(id: number) {
    this.router.navigateByUrl('/articles/detail?id=' + id);
  }

  gotoOverviewForTag(tagName: string) {
    this.tags$.pipe(
      take(1),
      map(tags => {
        if (tags) {
          const tag = tags.find(t => t.name === tagName);
          if (tag) {
            // this.fetchArticles(0, tag.id, null);
            this.router.navigateByUrl('/articles/overview?tagId=' + tag.id);
          }
        }
      })
    ).subscribe();
  }

  subscribeToNewsletter() {
    this.entryStore.select(selectMiningScamsEntryLinks).pipe(
      take(1),
      map(links => {
        if (this.email?.nativeElement) {
          this.toastVisible = true;
          this.miningScamsEntryHttpService.subscribe(this.configService.getModuleConfig().websiteId, this.email.nativeElement.value, links).subscribe(() => {
            setTimeout(() => this.toastVisible = false, 3000);
            this.toastMessage = 'You have successfully subscribed to our newsletter!';
          });
          this.email.nativeElement.value = '';
        }
      })
    ).subscribe();
  }

}
