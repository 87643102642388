import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {EntryState, selectTopics} from './shared/store';
import {map, takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {SecurityState, selectLoggedIn, selectUserAccounts, SessionUtil, UserAccountDto} from '@b2beyond/security';
import {ConfigService, LoadBlogEntryAction, ObjectUtil, Topic} from "@b2beyond/shared";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MiningScamsEntryHttpService} from "./shared/services/b2beyond.entry.service";

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private destroy$ = new Subject<void>();

  version: string = '1.0.0';
  title = 'Mining scams';

  @ViewChild('topics') topics!: ElementRef;
  @ViewChild('search') search!: ElementRef;

  loggedIn$: Observable<boolean> = this.securityStore.pipe(select(selectLoggedIn));
  account$ = this.securityStore.select(selectUserAccounts).pipe(
    map((accounts: Array<UserAccountDto> | undefined) => {
      if (accounts && !ObjectUtil.isEmptyArray(accounts)) {
        return accounts.filter(account => account.websiteId === this.configService.getModuleConfig().websiteId)[0];
      } else {
        return {id: null};
      }
    })
  );
  topics$ = this.entryStore.select(selectTopics);
  selectedTopic: Topic | undefined;
  form = new FormGroup({
    topicId: new FormControl(0, Validators.required),
    search: new FormControl('', Validators.required),
  });


  constructor(private readonly entryStore: Store<EntryState>,
              private readonly securityStore: Store<SecurityState>,
              private readonly oidcSecurityService: OidcSecurityService,
              public configService: ConfigService,
              private readonly activatedRoute: ActivatedRoute,
              protected readonly router: Router,
              protected readonly sessionUtil: SessionUtil,
              protected readonly entryService: MiningScamsEntryHttpService,
              protected readonly translateService: TranslateService) {
    // super();
    this.translateService.setDefaultLang('en');
    this.entryService.getBlogEntry();
    this.entryService.getWebsiteEntry();
  }

  ngOnInit(): void {
    // super.onInit(this.configService.getModuleConfig().entry_BASE_URL, this.configService.getModuleConfig().websiteId,
    //   this.configService.getModuleConfig().websiteScope, this.sessionUtil, this.router,
    //   this.securityEntryService, this.securityStore, this.oidcSecurityService, this.translateService);
    this.entryStore.dispatch(LoadBlogEntryAction());

    this.entryStore.select(selectTopics).pipe(
      takeUntil(this.destroy$),
      map(topics => {
        if (topics) {
          setTimeout(() => {
            $('select').niceSelect();
          }, 1000);
        }
      })
    ).subscribe();
  }

  gotoB2beyond(): Observable<string> {
    return this.sessionUtil.gotoB2beyondOrg(this.configService.getModuleConfig().b2beyond_url);
  }

  navigateToDetail(id: number) {
    this.router.navigateByUrl('/articles/detail?id=' + id);
  }

  searchArticles() {
    let search = this.search.nativeElement.value;
    let topicId = this.topics.nativeElement.value;
    if (isNaN(topicId)) {
      this.router.navigateByUrl('/articles/overview?search=' + search);
    } else {
      this.router.navigateByUrl('/articles/overview?topicId=' + topicId + '&search=' + search);
    }
  }

  isRouteActive(path: string) {
    return path === window.location.pathname;
  }
}
