import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {MiningScamsEntryHttpService} from '../../shared/services/b2beyond.entry.service';
import {Observable, withLatestFrom} from 'rxjs';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from "@angular/router";
import {EntryState, selectArticles, selectMiningScamsEntryLinks, selectTags, selectTopics} from "../../shared/store";
import {map, take, takeUntil} from "rxjs/operators";
import {Article, PageData} from "@b2beyond/shared";
import {AbstractSecurityComponent} from "@b2beyond/security";


@Component({
  selector: 'app-article-overview',
  templateUrl: './article-overview.component.html',
  styleUrls: ['./article-overview.component.scss'],
})
export class ArticleOverviewComponent extends AbstractSecurityComponent implements OnInit, OnDestroy {

  public articles$: Observable<PageData<Article> | undefined> = this.store.select(selectArticles);
  public topics$ = this.store.select(selectTopics);
  public tags$ = this.store.select(selectTags);

  public articlesContent: Article[] = [];

  private topicId = Number(this.route.snapshot.queryParamMap.get('topicId'));
  private tagId = Number(this.route.snapshot.queryParamMap.get('tagId'));

  constructor(private readonly titleService: Title,
              private readonly route: ActivatedRoute,
              private readonly router: Router,
              private readonly entryService: MiningScamsEntryHttpService,
              private readonly store: Store<EntryState>) {
    super();
  }

  ngOnInit(): void {
    const search = this.route.snapshot.queryParamMap.get('search');

    // this.route.queryParams.pipe(
    //   withLatestFrom(this.store.select(selectMiningScamsEntryLinks)),
    //   takeUntil(this.destroy$),
    //   map(([event, links]) => {
    //     if (links) {
    //       this.articlesContent = [];
    //       this.topicId = Number(event['topicId']) || 0;
    //       this.tagId = Number(event['tagId']) || 0;
    //       this.entryService.getArticles(this.topicId, this.tagId, search, links, undefined);
    //     }
    //   })
    // ).subscribe();

    this.store.select(selectMiningScamsEntryLinks).pipe(
      takeUntil(this.destroy$),
      map(links => {
        if (links) {
          this.entryService.getArticles(this.topicId, this.tagId, search, links, undefined);
        }
      })
    ).subscribe();

    this.articles$.pipe(
      takeUntil(this.destroy$),
      map(articles => {
        if (articles && articles.content) {
          this.articlesContent = [...this.articlesContent, ...articles.content];
        }
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigateToDetail(id: number) {
    this.router.navigateByUrl('/articles/detail?id=' + id);
  }

  gotoOverviewForTag(tagName: string) {
    this.tags$.pipe(
      take(1),
      map(tags => {
        if (tags) {
          const tag = tags.find(t => t.name === tagName);
          if (tag) {
            this.articlesContent = [];
            this.fetchArticles( null);
            this.router.navigateByUrl('/articles/overview?tagId=' + tag.id);
          }
        }
      })
    ).subscribe();
  }

  onScroll() {
    this.store.select(selectMiningScamsEntryLinks).pipe(
      withLatestFrom(this.articles$),
      take(1),
      map(([ links, articles ]) => {
        if (links && !articles?.last) {
          this.entryService.getArticles(this.topicId, this.tagId, '', links, articles);
        }
      })
    ).subscribe();
  }

  onTagClick(event$: number) {
    this.articlesContent = [];
    this.topicId = 0;
    this.tagId = event$;
    this.fetchArticles(null);
  }

  onTopicClick(event$: any) {
    this.articlesContent = [];
    this.topicId = event$;
    this.tagId = 0;
    this.fetchArticles(null);
  }

  private fetchArticles(search: string | null) {
    this.store.select(selectMiningScamsEntryLinks).pipe(
      take(1),
      map(links => {
        if (links) {
          this.entryService.getArticles(this.topicId, this.tagId, search, links, undefined);
        }
      })
    ).subscribe();
  }

}
