import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {MiningScamsEntryHttpService} from '../../shared/services/b2beyond.entry.service';
import {Observable} from 'rxjs';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute} from "@angular/router";
import {EntryState, selectMiningScamsEntryLinks, selectTags, selectTopics} from "../../shared/store";
import {map, take, takeUntil} from "rxjs/operators";
import {ArticleDetail} from "@b2beyond/shared";
import {AbstractSecurityComponent} from "@b2beyond/security";
import {FormControl, FormGroup, Validators} from "@angular/forms";


@Component({
  selector: 'app-start-page',
  templateUrl: './article-page.component.html',
  styleUrls: ['./article-page.component.css'],
})
export class ArticlePageComponent extends AbstractSecurityComponent implements OnInit, OnDestroy {

  public article$: Observable<ArticleDetail> | undefined;
  public topics$ = this.store.select(selectTopics);
  public tags$ = this.store.select(selectTags);

  commentForm: FormGroup | undefined;


  constructor(private readonly titleService: Title,
              private readonly meta: Meta,
              private readonly route: ActivatedRoute,
              private readonly entryService: MiningScamsEntryHttpService,
              private readonly store: Store<EntryState>) {
    super();
  }

  ngOnInit(): void {
    const id = Number(this.route.snapshot.queryParamMap.get('id'));
    this.store.select(selectMiningScamsEntryLinks).pipe(
      takeUntil(this.destroy$),
      map(links => {
        if (links) {
          this.article$ = this.entryService.getArticleForId(id, links);
          this.article$.pipe(
            takeUntil(this.destroy$),
            map((article: ArticleDetail) => {
              const shareLink = this.entryService.getLinkForId(article._links, 'self')?.href;
              this.titleService.setTitle(article.title);

              if (shareLink) {
                this.meta.addTags([
                  {name: 'description', content: article.description},

                  {name: 'og:url', property: 'og:url', content: shareLink},
                  {name: 'og:type', property: 'og:type', content: 'website'},
                  {name: 'og:title', property: 'og:title', content: article.title},
                  {name: 'og:description', property: 'og:description', content: article.description},
                  {name: 'og:image', property: 'og:image', content: article.cardImageLocation},

                  {name: 'twitter:card',  content: 'summary_large_image'},
                  {name: 'twitter:title', content: article.title},
                  {name: 'twitter:description', content: article.description},
                  {name: 'twitter:image', content: article.cardImageLocation},
                  {property: 'twitter:domain', content: 'miningscams.com'},
                  {property: 'twitter:url', content: shareLink}
                ]);
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();

    this.commentForm = new FormGroup({
      author: new FormControl('', Validators.required),
      description: new FormControl('', Validators.maxLength(1024)),
      _links: new FormControl([])
    });
  }

  saveComment() {
    if (this.commentForm?.valid && this.article$) {
      this.article$.pipe(
        take(1),
        map((article: ArticleDetail) => {
          let addComment = this.entryService.getLinkForId(article._links, 'action-add-comment');
          if (addComment) {
            let payload = JSON.parse(addComment.currentState);
            payload.author = this.commentForm?.controls['author'].value;
            payload.description = this.commentForm?.controls['description'].value;
            addComment.payload = payload;

            this.entryService.executeLink(addComment).subscribe(() => {
              this.commentForm?.reset();
            });
          }
        })
      ).subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onDetailClick(id: number) {
    this.store.select(selectMiningScamsEntryLinks).pipe(
      take(1),
      map(links => {
        if (links) {
          this.article$ = this.entryService.getArticleForId(id, links);
          this.article$.pipe(
            takeUntil(this.destroy$),
            map(article => {
              this.titleService.setTitle(article.title);
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }
}
