<!-- Start Blog Single -->
<!--<div class="col-lg-4 col-12">-->
  <div class="main-sidebar">
    <!-- Single Widget -->
<!--    <div class="single-widget search">-->
<!--      <div class="form">-->
<!--        <input type="email" placeholder="Search Here...">-->
<!--        <a class="button" href="#"><i class="fa fa-search"></i></a>-->
<!--      </div>-->
<!--    </div>-->
    <!--/ End Single Widget -->
    <!-- Single Widget -->
    <div class="single-widget category" *ngIf="topics$ | async as topics">
      <h3 class="title">Blog Topics</h3>
      <ul class="categor-list">
        <li *ngFor="let topic of topics"><a (click)="gotoOverviewForTopic(topic.id)" class="pointer">{{topic.title}}</a></li>
      </ul>
    </div>
    <!--/ End Single Widget -->

    <div id="frame" style="width: 300px;"><iframe data-aa='2182334' src='//ad.a-ads.com/2182334?size=300x250' style='width:300px; height:250px; border:0px; padding:0; overflow:hidden; background-color: transparent;'></iframe><a style="display: block; text-align: right; font-size: 12px" id="frame-link" href="https://aads.com/campaigns/new/?source_id=2182334&source_type=ad_unit&partner=2182334">Advertise here</a></div>

    <!-- Single Widget -->
    <div class="single-widget recent-post">
      <h3 class="title">Recent post</h3>
      <!-- Single Post -->
      <div class="single-post" *ngFor="let article of (recentArticles$ | async)">
        <div class="image">
          <img *ngIf="article.cardImageLocation" (click)="navigateToDetail(article.id)" class="pointer" style="width: 100px; height: 100px;" src="{{article.cardImageLocation}}" alt="#">
        </div>
        <div class="content">
          <h5><a (click)="navigateToDetail(article.id)" class="pointer">{{article.title}}</a></h5>
          <ul class="comment">
            <li><i class="fa fa-calendar" aria-hidden="true"></i>{{article.creationDate}}</li>
            <li><i class="fa fa-commenting-o" aria-hidden="true"></i>{{article.commentCount}}</li>
          </ul>
        </div>
      </div>
      <!-- End Single Post -->
    </div>
    <!--/ End Single Widget -->
    <!-- Single Widget -->
    <!--/ End Single Widget -->
    <!-- Single Widget -->
    <div class="single-widget side-tags">
      <h3 class="title">Tags</h3>
      <ul class="tag">
        <li *ngFor="let tag of (tags$ | async)"><a (click)="gotoOverviewForTag(tag.id)" class="pointer">{{tag.name}}</a></li>
      </ul>
    </div>
    <!--/ End Single Widget -->
    <!-- Single Widget -->
    <div class="single-widget newsletter">
      <h3 class="title">Newsletter</h3>
      <div class="letter-inner">
        <h4>Subscribe and get the <br> latest updates.</h4>
        <div class="form-inner">
          <input #email type="email" placeholder="Your email address" class="form-control" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" id="emailref2" name="emailref2" ngModel #emailref2="ngModel">

          <div *ngIf="emailref2.errors &&(emailref2.touched || emailref2.dirty)" class ="alert alert-danger margin-top-25">
            <div [hidden]="!emailref2.errors?.pattern">Invalid email pattern</div>
          </div>
          <a (click)="subscribeToNewsletter()" class="pointer">Subscribe</a>
        </div>
      </div>
    </div>
    <!--/ End Single Widget -->

    <div class="single-widget">
      <iframe src="https://nowpayments.io/embeds/donation-widget?api_key=GMG9TQB-Z6J4X4B-MPP6GSX-QX27DDC" width="348" height="550" frameborder="0" scrolling="no" style="overflow-y: hidden;">
        Can't load widget
      </iframe>
    </div>
  </div>
<!--</div>-->
<!--/ End Blog Single -->

<div id="myAlert" class="alert alert-info alert-dismissible fade" [ngClass]="{show: toastVisible}" role="alert">
  {{toastMessage}}
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
