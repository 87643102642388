import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {
  AbstractEntryHttpService,
  Article,
  ArticleDetail,
  ConfigService,
  Link,
  MessageDto,
  ObjectUtil,
  PageData,
  ReceivedBlogEntryAction,
  Resources,
  Tag,
  Topic
} from '@b2beyond/shared';
import {Store} from '@ngrx/store';
import {map, take} from 'rxjs/operators';
import {EntryState, ReceivedArticlesAction, ReceivedRecentArticlesAction, ReceivedTagsAction, ReceivedTopicsAction, ReceivedWebsiteEntryAction} from '../store';
import {OidcSecurityService} from 'angular-auth-oidc-client';


@Injectable()
export class MiningScamsEntryHttpService extends AbstractEntryHttpService {

  constructor(private readonly entryStore: Store<EntryState>,
              private readonly oidcService: OidcSecurityService,
              private readonly configService: ConfigService,
              http: HttpClient) {
    super(http);
  }

  getBlogEntry(): void {
    this.oidcService.isAuthenticated().pipe(
      take(1),
      map((loggedIn: boolean) => {
        this.http.get<Resources>(this.configService.getModuleConfig().entry_BLOG_URL).subscribe(value => {
          const links = Resources.of(value).getAllLinks();
          this.entryStore.dispatch(ReceivedBlogEntryAction({payload: {links}}));
        });
      })
    ).subscribe();
  }

  getWebsiteEntry(): void {
    this.oidcService.isAuthenticated().pipe(
      take(1),
      map((loggedIn: boolean) => {
        const websiteUrl = this.configService.getModuleConfig().entry_WEBSITE_URL;
        if (websiteUrl) {
          this.http.get<Resources>(websiteUrl).subscribe(value => {
            const links = Resources.of(value).getAllLinks();
            this.entryStore.dispatch(ReceivedWebsiteEntryAction({payload: {links}}));
          });
        }
      })
    ).subscribe();
  }

  getTopics(links: Link[]): void {
    const link = this.getLinkForId(links, "get-topics");
    this.executeLink<any>(link).pipe(
      take(1),
      map((topics: Topic[]) => {
        this.entryStore.dispatch(ReceivedTopicsAction({payload: topics}))
      })
    ).subscribe();
  }

  getTags(links: Link[]): void {
    const link = this.getLinkForId(links, "get-tags");
    this.executeLink<any>(link).pipe(
      take(1),
      map((tags: Tag[]) => {
        this.entryStore.dispatch(ReceivedTagsAction({payload: tags}))
      })
    ).subscribe();
  }

  getArticleForId(websiteId: number, links: Link[]) {
    const link = ObjectUtil.clone(this.getLinkForId(links, "get-article-for-id"));
    link.href = link.href.replace('{id}', websiteId);
    return this.executeLink<ArticleDetail>(link);
  }

  getArticles(topicId: number, tagId: number, search: string | null, links: Link[], pageData: PageData<Article> | undefined): void {
    const link = ObjectUtil.clone(this.getLinkForId(links, "get-articles"));
    link.href = link.href.replace('{topicId}', topicId).replace('{tagId}', tagId);
    if (pageData) {
      let page = pageData.number + 1;
      link.href = link.href + '&page=' + page + '&size=' + pageData.size;
    }
    if (search) {
      link.href = link.href.replace('{search}', search);
    } else {
      link.href = link.href.replace('{search}', '');
    }
    this.executeLink<PageData<Article>>(link).pipe(
      take(1),
      map((articles: PageData<Article>) => {
        this.entryStore.dispatch(ReceivedArticlesAction({payload: articles}))
      })
    ).subscribe();
  }

  getRecentArticles(links: Link[]): void {
    const link = ObjectUtil.clone(this.getLinkForId(links, "get-recent-articles"));

    this.executeLink<Article[]>(link).pipe(
      take(1),
      map((articles: Article[]) => {
        this.entryStore.dispatch(ReceivedRecentArticlesAction({payload: articles}))
      })
    ).subscribe();
  }

  subscribe(websiteId: number, email: string, links: Link[] | undefined): Observable<void> {
    if (links) {
      const link = ObjectUtil.clone(this.getLinkForId(links, "subscribe"));
      link.payload = {"email": email};
      return this.executeLink<void>(link);
    }
    return of();
  }

  sendMessage(message: MessageDto, links: Link[] | undefined): Observable<void> {
    if (links) {
      const link = ObjectUtil.clone(this.getLinkForId(links, "action-save-message"));
      link.payload = message;
      return this.executeLink<void>(link);
    }
    return of();
  }
}
