<!-- Start Blog Single -->
<section class="blog-single section" *ngIf="articles$ | async as articles">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="section-title float-left">
          <h2>Articles</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-12">
        <div class="container-fluid">
          <div class="row">
            <div class="masonry-with-columns"
                 infiniteScroll
                 [infiniteScrollDistance]="2"
                 [infiniteScrollThrottle]="50"
                 (scrolled)="onScroll()">
              <section>
                <div *ngFor="let article of articlesContent" class="margin-bottom-25">
                  <div class="card">
                    <div class="card-header" *ngIf="article.cardImageLocation">
                      <img src="{{article.cardImageLocation}}" alt="#">
                    </div>
                    <div class="card-body">
                      <div class="tags">
                        <span *ngFor="let tag of article.tags" class="tag tag-purple" (click)="gotoOverviewForTag(tag)">{{tag}}</span>
                      </div>
                      <h4>{{article.title}}</h4>
                      <p class="small">{{article.smallDescription}}</p>
                      <div class="user">
                        <div class="user-info">
                          <div class="small-title">
                            <small>Written by {{article.author}}</small>
                            <br/>
                            <small>On {{article.creationDate}}</small>
                          </div>
                          <a (click)="navigateToDetail(article.id)" class="float-right read-more-btn pointer">Read more ...</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-12">
        <app-sidebar
          (onTagClick)="onTagClick($event)"
          (onTopicClick)="onTopicClick($event)">
        </app-sidebar>
      </div>
    </div>
  </div>
</section>
<!--/ End Blog Single -->
