import {Action, createAction, props} from '@ngrx/store';
import {Article, Link, PageData, Tag, Topic} from '@b2beyond/shared';


export interface Actions extends Action {
  payload: any;
}

export const ReceivedWebsiteEntryAction = createAction(
  'RECEIVED_WEBSITE_ENTRY',
  props<{ payload: { links: Link[]; } }>()
);

export const ReceivedTopicsAction = createAction(
  'RECEIVED_TOPICS',
  props<{ payload: Topic[] }>()
);

export const ReceivedTagsAction = createAction(
  'RECEIVED_TAGS',
  props<{ payload: Tag[] }>()
);

export const ReceivedArticlesAction = createAction(
  'RECEIVED_ARTICLES',
  props<{ payload: PageData<Article> }>()
);

export const ReceivedRecentArticlesAction = createAction(
  'RECEIVED_RECENT_ARTICLES',
  props<{ payload: Article[] }>()
);

export const ReceivedPagesCountAction = createAction(
  'RECEIVED_ENTRY pages count',
  props<{ payload: number }>()
);
