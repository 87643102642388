import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {MiningScamsEntryHttpService} from '../../services/b2beyond.entry.service';
import {ReceivedBlogEntryAction} from '@b2beyond/shared';
import {tap} from 'rxjs/operators';
import {PaymentEntryHttpService} from '@b2beyond/payment';

@Injectable()
export class MiningScamsEffects {

  constructor(private actions$: Actions,
              private entryService: MiningScamsEntryHttpService,
              private paymentEntryService: PaymentEntryHttpService) {
  }


  entryBlogLoaded$ = createEffect(() =>
      this.actions$.pipe(
        ofType(ReceivedBlogEntryAction.type),
        tap((action: any) => {
          this.entryService.getTopics(action.payload.links);
          this.entryService.getTags(action.payload.links);
          // this.entryService.getArticles(0, 0, null, action.payload.links, undefined);
          this.entryService.getRecentArticles(action.payload.links);
        })
      ),
    {dispatch: false}
  );
}
