import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {StoreModule} from '@ngrx/store';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {environment} from '../environments/environment';
import {ArticlePageComponent, ContactPageComponent, PrivacyPolicyPageComponent, StartPageComponent,} from './pages';
import {HttpLoaderFactory} from './translate.loader';
import {EffectsModule} from '@ngrx/effects';
import {MiningScamsEffects} from './shared/store/effects/miningscams-effects.service';
import {entryReducer} from './shared/store';
import {B2beyondSharedModule} from './shared/b2beyond-shared.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {AppWrapper, SecurityModule} from '@b2beyond/security';
import {ToastrModule} from 'ngx-toastr';
import {AsyncPipe, CommonModule, NgIf, NgOptimizedImage, provideImgixLoader,} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CardComponent, ConfigService, ContextMenuComponent, LoaderComponent, SafeHtmlPipe, SharedLibraryModule} from '@b2beyond/shared';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';
import {MatTableModule} from '@angular/material/table';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSelectModule} from '@angular/material/select';
import {RouterModule} from "@angular/router";
import {AppRoutingModule} from "./app-routing.module";
import {ArticleOverviewComponent} from "./pages/article-overview";
import {SidebarComponent} from "./components/sidebar";
import {AuthModule, LogLevel} from "angular-auth-oidc-client";
import {InfiniteScrollDirective} from "ngx-infinite-scroll";
import {RssPageComponent} from "./pages/rss-page";
import {AboutPageComponent} from "./pages/about-page";


const APP_SPECIFIC_IMPORTS = [
  AppWrapper,
  CardComponent,
  LoaderComponent,
  MatNativeDateModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatBadgeModule,
  MatProgressBarModule,
  MatTableModule,
  MatExpansionModule,
  MatSelectModule,
  SafeHtmlPipe
];

@NgModule({
  declarations: [
    AppComponent,
    StartPageComponent,
    ArticlePageComponent,
    ArticleOverviewComponent,
    ContactPageComponent,
    SidebarComponent,
    RssPageComponent,
    AboutPageComponent,
    PrivacyPolicyPageComponent
  ],
  bootstrap: [AppComponent],
  exports: [
    TranslateModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ], imports: [CommonModule,
    B2beyondSharedModule,
    APP_SPECIFIC_IMPORTS,
    // B2beyondManagementModule,
    SecurityModule,
    // PaymentModule,
    SharedLibraryModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgOptimizedImage,
    InfiniteScrollDirective,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot(),
    StoreModule.forFeature('entry', entryReducer),
    EffectsModule.forRoot([MiningScamsEffects]),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    AuthModule.forRoot({
      config: {
        triggerAuthorizationResultEvent: true,
        historyCleanupOff: false,
        unauthorizedRoute: '/security/norights',
        authority: environment.oauth_BASE_PATH,
        redirectUrl: environment.url,
        postLogoutRedirectUri: environment.url,
        clientId: environment.clientId,
        scope: 'openid offline_access ' + environment.websiteScope,
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        autoUserInfo: false,
        logLevel: LogLevel.Debug,
        customParamsAuthRequest: {
          websiteId: environment.websiteId
        },
      },
    }),
    AsyncPipe,
    ContextMenuComponent,
    NgIf,
    TranslateModule], providers: [
    // provideImgixLoader('https://static.b2beyond.org/'),
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {

  constructor(private readonly configService: ConfigService) {
    configService.configure(environment);
  }

}
