import {ModuleConfig} from "@b2beyond/shared";

export const environment = {
  websiteId: 52,
  websiteScope: 'WEBSITE_52',
  clientId: 'the-mining-scams-client',
  production: true,
  b2beyond_url: 'https://b2beyond.org',
  url: 'https://b2beyond.org',
  websiteI18nUrl: 'https://i18n.b2beyond.org',
  oauth_BASE_PATH: 'https://oauth.b2beyond.org',
  user_BASE_PATH: 'https://api.b2beyond.org/user',
  entry_BASE_URL: 'https://api.b2beyond.org/user/entry',
  entry_BLOG_URL: 'https://api.b2beyond.org/blog/public/entry?websiteId=52',
  i18n_public_BASE_URL: 'https://api.b2beyond.org/i18n/public',
  clientToken: 'Basic dGhlLW1pbmluZy1zY2Ftcy1jbGllbnQ6YldsdWFXNW5MWE5qWVcxekxXTnNhV1Z1ZERwdGFXNXBibWN0YzJOaGJYTXRZMjl0TFdabA=='
} as ModuleConfig;
