import {Injectable} from '@angular/core';
import {TranslateLoader} from '@ngx-translate/core';
import {from, Observable} from 'rxjs';

import { HttpClient } from '@angular/common/http';
import {environment} from '../environments/environment';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new CustomTranslateLoader(http);
}

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {

  constructor(private httpClient: HttpClient) {
  }


  getTranslation(lang: string): Observable<any> {
    return from(this.httpClient.get(environment.i18n_public_BASE_URL + '?type=FLATTENED&websiteId=' + environment.websiteId + '&language=' + lang));
  }

}
