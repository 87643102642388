import {Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {EntryState, selectArticles, selectWebsiteEntryLinks} from '../../shared/store';
import {Article, MessageDto} from '@b2beyond/shared';
import {Title} from '@angular/platform-browser';
import {MiningScamsEntryHttpService} from "../../shared/services/b2beyond.entry.service";
import {take} from "rxjs/operators";


@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.css'],
})
export class ContactPageComponent implements OnDestroy {

  private destroy$ = new Subject<void>();

  @ViewChild('name') name: ElementRef = {} as ElementRef;
  @ViewChild('subject') subject: ElementRef = {} as ElementRef;
  @ViewChild('email') email: ElementRef = {} as ElementRef;
  @ViewChild('message') message: ElementRef = {} as ElementRef;


  constructor(private readonly titleService: Title,
              private readonly entryStore: Store<EntryState>,
              private readonly entryService: MiningScamsEntryHttpService,
              private readonly router: Router) {
    titleService.setTitle('Mining scams - contact us');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  sendMessage() {
    const message: MessageDto = {
      name: this.name.nativeElement.value.toString(),
      email: this.email.nativeElement.value.toString(),
      subject: this.subject.nativeElement.value.toString(),
      message: this.message.nativeElement.value.toString(),
    }

    this.entryStore.select(selectWebsiteEntryLinks).pipe(take(1)).subscribe(links => {
      this.entryService.sendMessage(message, links).subscribe(() => {
        this.name.nativeElement.value = '';
        this.subject.nativeElement.value = '';
        this.email.nativeElement.value = '';
        this.message.nativeElement.value = '';
      });
    });
  }
}
